.mh-Q1 { background: #ADEDB7; }
.mh-Q2 { background:#FFFFFF }
.mh-Q3 { background: #FFFFFF; }
.mh-Q4 { background: #E18893; }

.mh-theme-Q1 { color: #ADEDB7; background:transparent}
.mh-theme-Q2 { color: #FFFFFF; background:transparent}
.mh-theme-Q3 { color: #FFFFFF; background:transparent}
.mh-theme-Q4 { color: #E18893; background:transparent}


.rotateSpan {
	writing-mode: vertical-rl;
    transform: rotate(180deg);
};


.data-grid-header {
    color: white;
}