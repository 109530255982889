.groupStyles {
    display: 'flex';
    align-items: 'center';
    justify-content: 'space-between';
  }
.groupBadgeStyles {
    background-color: '#EBECF0';
    border-radius: '2em';
    color: '#172B4D';
    display: 'inline-block';
    font-size: 12;
    font-weight: 'normal';
    line-height: '1';
    min-width: 1;
    padding: '0.16666666666667em 0.5em';
    text-align: 'center';
  }