#sets-us-apart {
    color: white;
    width: 100%;
    background: #304771;
    margin-top: 0;
    margin-bottom: 0;
    height: 25rem;

}

#carousel-sets-us-apart-Indicators {
    display: flex;
    height: 17rem;
    align-items: center;
}

#sets-us-apart .title {
    color: #1e90b0;
}


  @media (max-width:767px )
  {
    #sets-us-apart {
         height: 15rem;
    }
    #carousel-sets-us-apart-Indicators {
        display: flex;
        height: 10rem;
        align-items: center;
    }    
    #sets-us-apart .carousel-text {
        /* padding-top: 1rem; */
        width: 80%;
    }
    #sets-us-apart h1 {
        font-weight: 300 !important;
        font-size: 1.5rem !important;
    }

    #sets-us-apart .Arrows {
        /* height: 90%; */
    }
  }
  @media (min-width:768px )
  {
    #sets-us-apart .carousel-text {
        /* padding-top: 5.5rem; */
        width: 70%;
    }
 }
  
  