.font-awesome {
  color: #bbbdc9;
}

@media (min-width: 601px) {
  .font-awesome-smallie a {
    min-width: 36px !important;
    min-height: 32px !important;
  }
  .font-awesome-standard a {
    min-height: 32px !important;
    min-width: 100px !important;
  }

  .font-awesome a {
    /* background-color: transparent !important; */
    padding: 5px 10px !important;
    color: #304771;
  }
}

@media (min-width: 1000px) {
  .font-awesome-smallie a {
    min-width: 36px !important;
    min-height: 32px !important;
  }
  .font-awesome-standard a {
    /* background-color: transparent !important; */
    min-height: 32px !important;
    min-width: 150px !important;
  }
  .font-awesome a {
    /* background-color: transparent !important; */
    padding: 5px 10px !important;
    color: #304771;
  }
}

.font-awesome .active {
  background-color: #bbbdc9 !important;
  color: #304771;
  min-height: 32px !important;
  padding: 5px 10px !important;
}
a {
  color: #304771;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
