.mh-Q1 { background: #ADEDB7; }
.mh-Q2 { background: #F3E18B; }
.mh-Q3 { background: #EBA5AC; }
.mh-Q4 { background: #DE7B86; }

.mh-theme-Q1 { color: #ADEDB7; background:transparent}
.mh-theme-Q2 { color: #F3E18B; background:transparent}
.mh-theme-Q3 { color: #EBA5AC; background:transparent}
.mh-theme-Q4 { color: #DE7B86; background:transparent}


.quartile-chart {
    height: 18rem;
}
.quartile-grid {
    /* height: 15rem; */
    padding-left: 5%;
    padding-right: 2%;
}

.quartile-def {
    height: 5rem;
    padding-left: 5%;
    padding-right: 2%;
}

select {

	/* width: 100%; */
    padding-left: 15px;
    padding-right: 20px;
	font-size: 1rem !important;
    color: #495057;
    border: 1px solid #ced4da;

	/* Here's the code we need */
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	 -o-appearance: none;
		appearance: none;
}

.select-wrapper {
	position: relative;	
}

.select-wrapper:after {
	font-family: FontAwesome;
  	content: '\f107';
  	font-size: 1rem;
  	position: absolute;
  	top: 20%;
  	right: 20%;
    color: #495057;
    font-weight: 900;
  	pointer-events: none;
}

select::-ms-expand {
  display: none;
}
.mh_header {
	border-right-color:#abafb3;
	border-top-color:#BBBDC9;
	/* border-bottom-color:#BBBDC9; */
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	/* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}
.mh_table {
	width: 100%;
}

.data-grid {
	overflow-y: auto;
  }