#analyzer {
  background: #2792c4;
  background-size: cover;
  padding: 2rem 0;
}

#analyzer .product-card-icon {
  display: inline-block;
  margin-left: 0;
  margin-bottom: 1rem;
  width: 5rem !important;
  height: 5rem !important;
}
#analyzer .home-product-card {
  background-color: transparent;
  border: none;
  padding: 1rem;
  min-width: 12rem;
}
#provider-search {
  min-height: 25rem;
}
#analyzer .home-search-box {
  display: flex;
  align-items: left;
  padding-top: 1rem;
  padding-left: 1rem;
  min-height: 16rem;
}
#analyzer .sm-text-blue {
  font-size: 12px;
  font-family: Roboto;
  color: #304771;
}
#analyzer .search-block {
  margin-bottom: 1rem;
}
#analyzer .search-bar-callout {
  font-weight: 200 !important;
  font-size: 3rem !important;
  font-family: Roboto;
}
#analyzer .search-bar-md {
  font-size: 22px;
  font-family: Roboto-Medium;
  color: #304771;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.new-feature-title {
  background-color: #304771;
  color: white;
  display: block;
  visibility: hidden;
}
.new-feature-title:hover {
  color: white;
}
#home-stay-informed .button-container {
  display: flex;
  flex-direction: row;
}
@media (min-width: 300px) {
  #analyzer {
    color: #fff;
  }
  #analyzer h1,
  #analyzer h2,
  #analyzer h3 {
    font-family: Roboto;
    font-weight: thin !important;
  }
  #analyzer h1 {
    font-size: 24pt !important;
  }
  #analyzer h2 {
    font-size: 16pt !important;
  }
  #analyzer h3 {
    font-size: 14pt !important;
  }
  #analyzer .bg-reports-and-tools {
    background-color: #304771;
    margin-top: 5em;
    padding-bottom: 2em;
  }
  #home-stay-informed .button-container {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 580px) {
  #analyzer h1 {
    font-size: 28pt !important;
  }
  #analyzer h2 {
    font-size: 18pt !important;
  }
  #analyzer h3 {
    font-size: 16pt !important;
  }
  #analyzer .bg-reports-and-tools {
    background-color: #304771;
    /* margin-top: -9em; */
    /* padding-top: 9em; */
    padding-bottom: 2em;
    padding-left: 3rem;
  }
}
@media (min-width: 991px) {
  .new-feature-title {
    background-color: #fcb540;
    color: white;
    display: block;
    visibility: visible;
  }
  #analyzer h1 {
    font-size: 32pt !important;
  }
  #analyzer h2 {
    font-size: 22pt !important;
  }
  #analyzer h3 {
    font-size: 18pt !important;
  }
  #analyzer .search-bar-callout {
    font-weight: 200 !important;
    font-size: 2rem !important;
    font-family: Roboto;
  }
  #analyzer .home-search-box {
    display: flex;
    align-items: left;
    padding-top: 1rem;
    min-height: 16rem;
  }
  #analyzer .bg-reports-and-tools {
    background-color: #304771;
    padding-bottom: 2em;
  }
}
@media (min-width: 1350px) {
  #analyzer .white-search-box {
    border-radius: 25px;
    background: white;
    margin: auto;
    margin-top: 5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #bbbdc9;
    height: 18rem;
  }
}
#home-stay-informed {
  width: 100%;
  font-family: Roboto-Medium;
  color: #bbbdc9;
}
@media (min-width: 601px) {
  #home-stay-informed .title-text {
    font-size: 26px;
  }
}
@media (min-width: 1350px) {
  #home-stay-informed .title-text {
    font-size: 32px;
    margin-bottom: 3em;
  }
}
#home-stay-informed .body-text {
  font-size: 16px;
  font-family: Roboto-Regular;
  color: black;
  margin-top: 1em;
  margin-bottom: 2em;
}
#home-stay-informed .border-right {
  border-right: 10px solid gray;
}
#home-stay-informed .border-left {
  border-left: 10px solid gray;
}
