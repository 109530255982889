.page-nav-icon{
    height:20px;
    width:20px;
    background-color:transparent;
    display:inline-block;
    margin-left: 10px;
    margin-right: 10px;
}

.menu-navbar {
    background-color:#304771;
    color: #fff;
    font-size: 100%;
    font-family: Roboto;
    box-shadow: 0 0 1px rgba(0,0,0,.5), 0 1px 1px rgba(0,0,0,.5) !important;
    margin-bottom:1px !important;
    /* -webkit-box-shadow: 0 0 1px rgba(0,0,0,.5), 0 1px 1px rgba(0,0,0,.5);  Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    /* -moz-box-shadow:0 0 1px rgba(0,0,0,.5), 0 1px 1px rgba(0,0,0,.5) ;  Firefox 3.5 - 3.6 */
    /* /* box-shadow:0 0 1px rgba(0,0,0,.5), 0 1px 1px rgba(0,0,0,.5) ;  Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */

}

.page-navbar {
    background-color:#304771;
    color:#fff;
    font-size: 100%;
    font-family: Roboto;
    /* box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24) !important; */
    /* -webkit-box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24);  Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    /* -moz-box-shadow:0 0 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24);  Firefox 3.5 - 3.6 */
    /* box-shadow:0 0 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24);  Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    margin-bottom:2px !important;
}
.page-navbar-sm {
    background-color:#304771;
    color: #fff;
    font-size: 12px;
    font-family: Roboto;
    /* box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24) !important; */
    /* -webkit-box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24);  Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    /* -moz-box-shadow:0 0 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24);  Firefox 3.5 - 3.6 */
    /* box-shadow:0 0 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24);  Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    margin-bottom:2px !important;
}

.page-navbar #pageNav {
    margin: 0 auto;
}
#pageNav .navbar {
    text-align: center;
    font-Weight: 500;
    min-Height: 56px;
    width: 100%;
    margin-bottom: 0;
}
#pageNav .navbar li {
    cursor: pointer;
}
#pageNav .navbar li.active {
    cursor: default;
}
#pageNav .navbar li:hover, #pageNav .navbar li:active, #pageNav .navbar li:focus {
    color: #f3a66e;
}

.facilityData {
    padding-left: 0.5rem;
    display: flex !important;
}

.signIn-icon {
    background: #304771;
    flex-shrink: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar-submenu {
    background-color: #E8E8E8;
    color: #000;
    font-family: Roboto;    
    font-size: 12px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight:500;
    box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24) !important;
}
.page-navbar-link.active {
    color: #BBBDC9 !important;
    font-weight:500;
    
}
.form-control {
    border-radius: 0rem !important;
}

.menu-link-label {
    color: #304771 !important;
    font-weight: 400 !important;
  }
  .menu-link-label:hover{
    color: #5c5c5c !important;
  }
  .rbt-input-wrapper{
      padding: 6px 3px;
      font:normal normal normal 16px/1 FontAwesome, Roboto;
  }
  .dropdown-menu {
      border-radius:0rem !important;
      border: 0 !important;
  }
  .facility-icon {
      font-family: FontAwesome;
  }
/* .close {display: none;} */