/* Amplify Theme Colors */
:root {
  --amplify-primary-color: rgba(12,89,145,1);
  --amplify-primary-tint: rgba(243,166,110,1);
  --amplify-primary-shade: #070606;
  --amplify-font-family: 'Roboto', sans-serif;
}
  
table th,
table td {
  padding: 0.3rem 10px !important;
  vertical-align: top;
}

.bg-info {
  background-color: #304771 !important;
}
.mh-card {
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  min-width: 10rem !important;
  max-width: 16rem !important;
}

.mh-sm-card {
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  min-width: 0.5rem !important;
}

.mh-lg-card {
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  min-width: 14rem !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  color: #fff !important;
  background-color: #909590 !important;
  border-color: #909590 !important;
}
.btn-primary:hover,
.btn-primary:focus {
  box-shadow: 0 0 0 0.1rem #bbb !important;
}

.btn-outline-primary {
  color: #304771;
  background-color: transparent;
  background-image: none;
  border-color: #304771 !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #304771 !important;
  border-color: #304771 !important;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  color: #fff !important;
  box-shadow: 0 0 0 0.2rem rgba(48, 71, 113, 0.5);
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #304771;
  border-color: #304771;
}

.text-primary {
  color: #304771 !important;
}
.bg-primary {
  background-color: #304771 !important;
}
.bg-secondary {
  background-color: #f1f1f1 !important;
}

/* Section Tag for each page of the site */
.content-page {
}

.accordion {
  color: #304771;
  flex: 0 0 80%;
  max-width: 80%;
}

.accordion > div {
  margin: 10px 0px;
}

.accordion > div > div:first-of-type:before {
  float: right !important;
  font-family: FontAwesome;
  content: "\f067";
  padding-right: 5px;
  font-weight: 100;
}

.accordion > div.open > div:first-of-type:before {
  float: right !important;
  font-family: FontAwesome;
  content: "\f068";
  padding-right: 5px;
  font-weight: 100;
}

/* Header */

.accordion > div > div:first-of-type {
  padding: 0px 5px 5px 5px;
  background-color: #f1f2f2;
  color: #304771;
  font-size: "large";
  margin: 20px, 0px;
  padding: 0px, 30px;
  content: "";
}

/* Content */

.accordion > div > div:last-of-type {
  color: #2c302e;
}

.accordion-item {
  padding: 0px 30px;
}

.aboutcallout {
  border-top-color: #304771 !important;
  border-top-width: 2px !important;
  border-top-style: solid !important;
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin-top: 0.5rem;
  min-width: 13.5rem;
}

.chart-logo {
  position: relative;
  bottom: 25pt;
  left: 20pt;
  font-size: 8pt;
  font-weight: 400;
}

.hero-flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  justify-content: space-evenly;
}

.hero-flex-item {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 15px;
  min-width: 180px !important;
}

.about-callout-text {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 100;
}

.about-callout-heading {
  font-family: Roboto;
  font-size: 48px !important;
  font-weight: 200;
}

.about-callout-copy {
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: 400;
}
.about-callout-copy-small {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
}

.table-header-fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

/*--------------Replacement of React Tabs Formatting----------------*/
.react-tabs__tab {
  display: inline-block;
  border: 1px solid #304771 !important;
  position: relative;
  list-style: none;
  padding: 6px 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.9rem;
  color: rgba(40,61,98,1);
}
.react-tabs__tab-first {
  border-left: 1px solid;
}
.react-tabs__tab--selected {
  background: #304771;
  border: 0px solid #304771;
  color: white !important;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px #304771;
  border-color: #304771;
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
.trend-and-rating {
  background-color: white;
}
.bg-Q1 {
  background-color: #304771;
}
.bg-Q2 {
  background-color: #4fb591;
}
.bg-Q3 {
  background-color: #fce1b5;
}
.bg-Q4 {
  background-color: #cec0f3;
}
.fixed-in-top {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.sparkline-stacked {
}
.sparkline-stacked svg {
  height: 18px;
  padding: 0;
  width: 125px;
}

#menuBar {
  margin-right: 2.2rem;
  font-weight: bold;
}

/* Tablet md in Bootstrap */
@media (max-width: 767px) {
  h1 {
    font-weight: 300 !important;
    font-size: 2rem !important;
  }

  .about-callout-copy-small {
    font-family: Roboto;
    font-size: 1.2rem;
    font-weight: 400;
  }
  .card-deck-primary-bg {
    background-color: #2193b3;
  }
}

/* Tablet lg in Bootstrap */
@media (min-width: 768px) {
  .modal {
    padding: 0 !important;
  }
  .modal-dialog {
    max-width: 80% !important;
  }

  .modal-content {
    border-radius: 0 !important;
  }

  .modal-4 {
    width: 430px !important;
    border-radius: 0 !important;
  }
}

@media (min-width: 992px) {
  .fixed_header {
    position: fixed;
    z-index: 50;
    top: 84px !important;
  }
}

@media (min-width: 768px) {
  .fixed_header {
    position: fixed;
    z-index: 50;
    top: 84px !important;
  }
}
@media (max-width: 767px) {
  .fixed_header {
    position: static;
    z-index: 50;
    top: 0;
  }
}
